/*スマホ用のcssを記述*/
body {
  font-family: sans-serif;
  background-color: #ff2994;
  background-image: url("./sustapi_top_bg_dot.png");
}

/* ハンバーガーメニュ */
/* メニュー3本線ボタン */
.menu-trigger {
  position: fixed;
  top: 4px;
  right: 10px;
  z-index: 3;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.menu-trigger span {
  position: absolute;
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background-color: #ffffff;
  transition: all 0.4s;
}

/* 閉じているボタン */
.menu-trigger span:nth-of-type(1) {
  top: 0;
}
.menu-trigger span:nth-of-type(2) {
  top: 18px;
}
.menu-trigger span:nth-of-type(3) {
  bottom: 0;
}

/* 開いてるボタン */
.menu-trigger.is-active span:nth-of-type(1) {
  transform: translateY(18px) rotate(-45deg);
}
.menu-trigger.is-active span:nth-of-type(2) {
  opacity: 0;
}
.menu-trigger.is-active span:nth-of-type(3) {
  transform: translateY(-18px) rotate(45deg);
}

/* メニュー本体 */
.menu {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1;
  width: 70vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  background: #555;
  opacity: 0.8;
}

.menu-item {
  width: 100%;
  height: auto;
  padding: 1rem 1rem;
  text-align: left;
  color: #fff;
}

.menu-header {
  width: 100%;
  height: auto;
  padding: 1rem 1rem;
  text-align: center;
  color: #fff;
  border-bottom: solid 2px #ffffff;
}

/* アニメーション前のメニューの状態 */
.menu {
  transform: translateX(70vw);
  transition: all 0.3s linear;
}
/* アニメーション後のメニューの状態 */
.menu.is-active {
  transform: translateX(0);
}

/* モーダル */
#overlay {
  /* 画面全体を覆う設定 */
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* 画面の中央に要素を表示させる設定 */
  display: flex;
  align-items: center;
  justify-content: center;
}

#content {
  z-index: 11;
  width: 85%;
  padding: 20px;
  background: #fff;
}

/* アイコンレイアウト */
.icon-circle {
  border: solid;
  border-radius: 50%;
  position: relative;
}

.icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.acd-menu button {
  display: block;
  padding: 15px;
  text-decoration: none;
  color: #000;
}

.acd-menu span {
  display: block;
  padding: 15px;
  text-decoration: none;
  color: #000;
}

label {
  display: block;
  margin: 0 0 4px 0;
  padding: 15px;
  line-height: 1;
  color: #fff;
  background: rgb(95, 100, 95);
  cursor: pointer;
}

input {
  display: none; /* チェックボックスを消す */
}

.menu ul {
  margin: 0;
  padding: 0;
  background: #f4f4f4;
  list-style: none;
}

.menu li {
  height: 0;
  overflow: hidden;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

#menu_bar01:checked ~ #links01 li,
#menu_bar02:checked ~ #links02 li,
#menu_bar03:checked ~ #links03 li {
  height: 54px;
  opacity: 1;
}

#menu_bar02:checked ~ #links02 li:last-child {
  height: 91px;
  opacity: 1;
}

.acd-label {
  position: relative;
}

.acd-label:after {
  content: ""; /* 空でも必要 */
  /* 位置調整 */
  position: absolute;
  top: 8px;
  right: 16px;
  /* 矢印形状調整 */
  border-bottom: solid 4px #fff;
  border-right: solid 4px #fff;
  width: 18px;
  height: 18px;
  transform: rotate(45deg);
  /* アニメーション */
  transition: all 0.3s;
}

.acd-check:checked + .acd-label:after {
  top: 16px;
  transform: rotate(225deg);
}

/*QR CODE START*/
.qr_wrapper {
  padding: 8.5vh 0 0;
}

.qr-message {
  width: 75vh;
  margin: 0 auto 3vh;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
}

.qr-code {
  width: 50vh;
  margin: 0 auto 6vh;
  text-align: center;
}
/*QR CODE END*/

/*TOP*/
/*SP START*/
@media (max-width: 768px) {
  img {
    box-sizing: border-box;
    width: 100%;
    height: auto;
  }
  body {
    height: 100vh;
    overflow: hidden;
  }

  .top_wrapper {
    padding: 8.5vh 0 0;
  }
  .top-logo {
    width: 50vh;
    margin: 0 auto 3vh;
  }

  .top-drink {
    width: 18vh;
    margin: 0 auto 6vh;
    text-align: center;
  }

  .top-start {
    width: 32vh;
    margin: auto;
  }
}
/*SP END*/

/*PC START*/
@media (min-width: 769px) {
  img {
    box-sizing: border-box;
    width: 100%;
    height: auto;
  }
  body {
    height: 100vh;
    overflow: hidden;
  }

  .top_wrapper {
    padding: 8.5vh 0 0;
  }
  .top-logo {
    width: 50vh;
    margin: 0 auto 3vh;
  }
  .top-drink {
    width: 18vh;
    margin: 0 auto 6vh;
    text-align: center;
  }

  .top-start {
    width: 32vh;
    margin: auto;
  }

  .top-start {
    width: 32vh;
    margin: auto;
  }
} /*PC END*/
